// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'eu-west-1:abaa47d4-c768-4851-8293-01d86d9ab708',
    'aws_cognito_region': 'eu-west-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'moneiwalletstaging-hosting-mobilehub-2006345325',
    'aws_content_delivery_bucket_region': 'eu-west-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd21ffc12fh28z9.cloudfront.net',
    'aws_mobile_analytics_app_id': 'fff8a7b237d8480b9e6c209de2c0757b',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'd5dc527a-a003-472b-a259-085080f553ac',
    'aws_project_name': 'monei-wallet-staging',
    'aws_project_region': 'eu-west-1',
    'aws_resource_name_prefix': 'moneiwalletstaging-mobilehub-2006345325',
}

export default awsmobile;
